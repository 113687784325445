import {useCallback, useMemo} from 'react';

import {type OrganisationGroupExternal} from '@onroadvantage/onroadvantage-api';
import {captureException} from '@sentry/react';
import {useAuth} from 'oidc-react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {routes} from '../../../routes';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {
  isActiveLytxEvent,
  isActiveLytxPreEvent,
  isActiveSurfSightEvent,
} from '../utils/eventDistinctions.ts';
import {enhanceFormData, shouldSkipEscalation} from '../utils/feedbackUtils';
import {
  fetchHindsightData,
  fetchOrganisationGroup,
  HindsightParams,
} from '../utils/fetchEventHindsightAndOrganisationData.ts';

export interface EventDetailsData {
  group: OrganisationGroupExternal;
}

export const useEventFeedback = () => {
  const {
    webSocket,
    lookupTableData,
    notificationComments,
    showOnBreakModal,
    resetContext,
    hindsightBehaviourOutcomeData,
    setHindsightBehaviourOutcomeData,
    eventDetailsData,
    behaviours,
    setOnEventSelectedShortcuts,
  } = useEventReviewContext();
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const lateClipSelected = useMemo(
    () =>
      notificationComments.length > 0 &&
      notificationComments[0].text.includes('Late Clip'),
    [notificationComments],
  );

  const generateHindsightData = useCallback(
    async (ids?: number[]) => {
      setHindsightBehaviourOutcomeData(null);
      if (ids == null || ids?.length === 0) return null;

      const currentEvent = webSocket.events[0];
      if (!currentEvent) return null;

      try {
        const params: HindsightParams = {behaviourIds: ids};

        if (isActiveLytxPreEvent(currentEvent)) {
          params.preEvent = true;
        } else if (
          isActiveLytxEvent(currentEvent) &&
          eventDetailsData?.group?.id
        ) {
          params.organisationGroupExternalId = eventDetailsData.group.id;
        } else if (isActiveSurfSightEvent(currentEvent)) {
          const detailsResponse = await fetchOrganisationGroup(
            currentEvent,
            'Surfsight',
          );
          if (detailsResponse?.organisationId) {
            params.organisationGroupExternalId = detailsResponse.organisationId;
          }
        }

        const response = await fetchHindsightData(params);
        if (response) {
          setHindsightBehaviourOutcomeData(response);
        }

        return response;
      } catch (e) {
        captureException(e);
        return null;
      }
    },
    [
      eventDetailsData?.group?.id,
      setHindsightBehaviourOutcomeData,
      webSocket.events,
    ],
  );

  const onSubmit = useCallback(
    async (formValues: Record<string, boolean>) => {
      const filteredValues = Object.fromEntries(
        Object.entries(formValues).filter(([, value]) => value),
      );

      const {ids} = enhanceFormData(
        filteredValues,
        lookupTableData.behaviorLookupTable,
        lookupTableData.observationLookupTable,
      );

      const skipEscalation =
        shouldSkipEscalation(behaviours ?? [], ids) || lateClipSelected;

      if (ids != null) {
        void generateHindsightData(ids);
      }

      if (searchParams.get('notifications') != null || skipEscalation) {
        webSocket.queueReview(
          formValues,
          lookupTableData,
          auth,
          notificationComments,
          new Date(),
        );

        if (!showOnBreakModal) {
          navigate(routes.eventReview.review);
          searchParams.delete('notifications');
          resetContext();
        }
      } else {
        setSearchParams({notifications: 'true'});
      }
    },
    [
      lookupTableData,
      behaviours,
      searchParams,
      generateHindsightData,
      webSocket,
      auth,
      notificationComments,
      showOnBreakModal,
      navigate,
      resetContext,
      setSearchParams,
      lateClipSelected,
    ],
  );

  const addFormValueToChecklist = useCallback(
    (shortcutValue?: string) => {
      if (shortcutValue == null) {
        return;
      }
      setOnEventSelectedShortcuts((prev) => {
        if (prev.includes(shortcutValue)) {
          return prev.filter((item) => item !== shortcutValue);
        }
        return [...prev, shortcutValue];
      });
    },
    [setOnEventSelectedShortcuts],
  );

  return {
    hindsightBehaviourOutcomeData,
    onSubmit,
    addFormValueToChecklist,
  };
};
